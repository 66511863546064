import post from 'axios';
class ToncenterRPC {
    constructor(rpcURL) {
        this._rpcURL = rpcURL;
    }
    get rpcURL() { return this._rpcURL; }
    static genRpcBody(method, params) {
        return { method, params, id: '', jsonrpc: '2.0' };
    }
    async request(method, params) {
        const data = await post({
            url: this._rpcURL,
            data: ToncenterRPC.genRpcBody(method, params),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-API-Key': 'eb7febb199841f9b20a7f6ca161be09918c71c753d210cb30a46996815d8ca4d'
            }
        });
        if (data.status === 200) {
            return data;
        }
        console.error(data);
        return data;
    }
}
export { ToncenterRPC };
