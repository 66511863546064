import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icon28AddOutline, Icon28DoorArrowRightOutline, Icon28SortOutline } from '@vkontakte/icons';
import { AppRoot, SplitLayout, SplitCol, View, Panel, Group, Div, FormItem, Input, Button, Avatar, IconButton, CardGrid, Card, SimpleCell, Headline, Banner } from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';
import './style.css';
import React, { useEffect } from 'react';
import { useTonhubConnect } from 'react-ton-x';
import { chains } from '@web3modal/ethereum';
import { useAccount, useBalance, useConnectModal, useDisconnect, useContractRead } from '@web3modal/react';
import { Address, BOC, Builder, Slice } from 'ton3';
import { ToncenterRPC } from './logic/tonapi';
import wc from './static/wc.png';
import wagmigotchiABI from './abi.json';
import { St } from './lang';
import { RootSteps } from './hooks/write';
let lang = 'en';
function Ln(obj) {
    return lang === 'en' ? obj.en : obj.ru;
}
// const addressUSDT = '0x5FbDB2315678afecb367f032d93F642f64180aa3'
const addressUSDT = '0xdAC17F958D2ee523a2206206994597C13D831ec7';
const addressBridge = '0xC9a50dc3BCBEf6A65d6C70f7F989125d7F38c0d5';
const addressBridgeTon = 'EQAgWbPwXMyVvOio3lT8q71cmVhgX9AWv4TwugpCoUo1cShR';
const addressUSDTTON = 'EQAw00GCfqn-lZMHpqY7RH6kmNy0I8kHQXSWIWADuIbcAj2n';
const ch = chains.mainnet.id;
function createTonRPC() {
    return new ToncenterRPC('https://mainnet.tonhubapi.com/jsonRPC');
}
function truncAddress(address) {
    return `${address.slice(0, 8)}...${address.slice(-8)}`;
}
function balanceString(balance2) {
    return Number(Number(balance2).toFixed(2)).toLocaleString('ru');
}
let isInstalledBtn = false;
export const App = (props) => {
    const [activePanel, setActivePanel] = React.useState('panel1');
    const [typeV, setTypeV] = React.useState(0); // menu
    const [inp1, setInp1] = React.useState('10');
    const [inp2, setInp2] = React.useState('0');
    const [step, setStep] = React.useState(0);
    const { open } = useConnectModal();
    const { address, isConnected, connector } = useAccount();
    const { data, isLoading } = useBalance({
        addressOrName: address ?? '',
        enabled: Boolean(address)
    });
    const disconnect = useDisconnect();
    const connectTonHub = useTonhubConnect();
    const resGetBalance = useContractRead({
        addressOrName: addressUSDT,
        contractInterface: wagmigotchiABI,
        functionName: 'balanceOf',
        args: [address ?? '0x91332b1Ce92FE037EBA10bA5b46e9C39d4aCB4C0'],
        chainId: ch,
        watch: true
    });
    const [balanceETH, setBalanceETH] = React.useState(0);
    const [balanceTON, setBalanceTON] = React.useState(0);
    const [balanceTONFULL, setBalanceTONFULL] = React.useState(0);
    const tonrpc = createTonRPC();
    const TgObj = window.Telegram.WebApp;
    const TgObjFull = window.Telegram;
    const isTg = window.Telegram.WebApp.initData !== '';
    const coins = [{ name: 'USDT (ERC20)', id: 0 }, { name: 'USDT (TON)', id: 1 }];
    const isDesktop = window.innerWidth >= 1000;
    const minAmountRub = 10;
    const maxAmountRub = 100000;
    // работа с мэин кнопкой
    function disableBtn() {
        // const TgObj = window.Telegram.WebApp
        TgObj.MainButton.show();
        TgObj.MainButton.disable();
        if (TgObj.colorScheme === 'dark') {
            TgObj.MainButton.color = '#3d3d3f';
            TgObj.MainButton.textColor = '#86858b';
        }
        else {
            TgObj.MainButton.color = '#e8e8e8';
            TgObj.MainButton.textColor = '#e2e3de';
        }
    }
    function enableBtn(balfix = balanceTONFULL) {
        if (connectTonHub.state.type === 'online' && (data?.formatted ?? 0) > 0.005 && balfix > 1) {
            TgObj.MainButton.show();
            TgObj.MainButton.enable();
            TgObj.MainButton.color = '#31b545';
            TgObj.MainButton.textColor = '#ffffff';
        }
        else if ((data?.formatted ?? 0) < 0.005 || balfix < 1) {
            disableBtn();
        }
        console.log('balanceTONFULL', balfix);
        // console.log('data?.formatted', data?.formatted)
    }
    function setTextBtn(text) {
        TgObj.MainButton.text = text;
    }
    // закрытие модалки
    function closeModal() {
        if (isTg) {
            TgObj.showAlert(Ln(St.cancel), () => {
                setActivePanel('panel1');
                TgObj.MainButton.show();
                setBackButton(false);
            });
        }
        else {
            setActivePanel('panel1');
        }
    }
    function setBackButton(type = true) {
        if (type) {
            TgObj.BackButton.show();
            TgObj.BackButton.onClick(() => {
                closeModal();
            });
        }
        else {
            TgObj.BackButton.hide();
        }
    }
    // функция запуска обмена
    function approveS() {
        // setStep(1)
        TgObj.MainButton.hide();
        setActivePanel('panel2');
        setBackButton(true);
        // approve.write()
    }
    // работа с локалсторедж тонхаб
    function getTonHub() {
        // const loca = localStorage.getItem('tonhub')
        // if (loca === null || loca === '') {
        //     if (isTg) {
        //         return TgObjFull.Utils.sessionStorageGet('tonhub')
        //     }
        //     return null
        // }
        // try {
        //     const strLoca = JSON.parse(loca)
        //     if (strLoca) {
        //         // console.log(loca)
        //         return strLoca
        //     }
        //     return null
        // } catch (error2) {
        //     console.log(error2)
        //     return null
        // }
        return null;
    }
    function saveTonHub(obj) {
        console.log('saved', obj);
        localStorage.setItem('tonhub', JSON.stringify(obj));
        if (isTg) {
            TgObjFull.Utils.sessionStorageSet('tonhub', obj);
        }
    }
    function clearTonHub() {
        localStorage.setItem('tonhub', JSON.stringify(null));
        if (isTg) {
            TgObjFull.Utils.sessionStorageSet('tonhub', null);
        }
    }
    // работа с инпутами ввода суммы
    function inputNumberSet(value, _type) {
        disableBtn();
        console.log(value);
        if (/^([0-9]{0,7})$/.test(value)) {
            if (value !== '') {
                const numValue = Number(Number(value).toFixed(0));
                const isN = Number.isNaN(numValue);
                if (isN === false) {
                    if (numValue >= minAmountRub) {
                        if (numValue < maxAmountRub) {
                            enableBtn();
                            setTextBtn(`${Ln(St.exchange)} ${numValue} USDT`);
                            return value;
                        }
                        enableBtn();
                        setTextBtn(`${Ln(St.exchange)} ${maxAmountRub} USDT`);
                        return `${maxAmountRub}`;
                    }
                    setTextBtn(Ln(St.exchange));
                    return (numValue > 0 ? value : '0');
                }
                setTextBtn(Ln(St.exchange));
                console.log('erro1');
                return '';
            }
            setTextBtn(Ln(St.exchange));
            return '';
        }
        setTextBtn(Ln(St.exchange));
        return '';
    }
    function calculateAmountNew(amount) {
        const percentFee = 1;
        const minimumFee = 0.5;
        if (amount === '') {
            setInp1('');
            setInp2('');
        }
        else {
            const amountN = Number(amount);
            const amountTo = amountN - (((amountN / 100) * percentFee) + minimumFee);
            setInp2(parseFloat(amountTo.toFixed(10)).toFixed(2));
            setInp1(amount);
        }
    }
    function connectLoad() {
        // TgObj.MainButton.hide()
        TgObj.MainButton.disable();
        open();
    }
    function disconnectBtn() {
        disconnect();
    }
    function disconectTon() {
        // connectTonHub.state.type = 'pending'
        clearTonHub();
        props.setConnectionState({ type: 'initing' });
    }
    function LinkTG(url) {
        let Url = url;
        if (url.indexOf('ton://') > -1) {
            Url = url.replace('ton://', 'https://tonhub.com/');
        }
        if (url.indexOf('ton-test://') > -1) {
            Url = url.replace('ton-test://', 'https://tonhub.com/');
        }
        TgObj.openLink(Url);
    }
    // работа с тоном
    async function getJettonWalletAddress(addressJetton, addressUser) {
        let jwallAddressBounceable;
        const addressO = new Address(addressUser);
        const builder = new Builder().storeAddress(addressO);
        const boc2 = BOC.toBase64Standard(builder.cell());
        const jwallAddressResp2 = await tonrpc.request('runGetMethod', {
            address: addressJetton,
            method: 'get_wallet_address',
            stack: [['tvm.Slice', boc2]]
        });
        if (jwallAddressResp2.data.ok === true) {
            if (jwallAddressResp2.data.result.exit_code === 0) {
                const addr3 = jwallAddressResp2.data.result.stack[0][1].bytes;
                if (addr3 !== null) {
                    const addressWallet = Slice.parse(BOC.fromStandard(addr3)).loadAddress();
                    console.log('addressWallet', addressWallet);
                    if (addressWallet !== null) {
                        jwallAddressBounceable = addressWallet.toString('base64', { bounceable: true });
                    }
                    else {
                        console.error('#222 errror', jwallAddressResp2.data);
                    }
                }
                else {
                    console.error('#999 error', jwallAddressResp2.data);
                }
            }
            else {
                console.error('jwallAddressResp2 #2', jwallAddressResp2.data);
            }
        }
        else {
            console.error('jwallAddressResp2 #1', jwallAddressResp2.data);
        }
        // }
        // } else {
        //     console.error(jwallAddressResp.data)
        // }
        return jwallAddressBounceable;
    }
    async function getJettonBalanceFromWalletAddress(addressWallet) {
        let balanceJetton = 0;
        if (addressWallet) {
            const jwallCheckAddressResp = await tonrpc.request('getAddressInformation', { address: addressWallet });
            if (jwallCheckAddressResp.data.result) {
                if (jwallCheckAddressResp.data.result.state !== 'uninitialized') {
                    const jwallBalanceResp = await tonrpc.request('runGetMethod', {
                        address: addressWallet,
                        method: 'get_wallet_data',
                        stack: []
                    });
                    if (jwallBalanceResp.data.ok === true) {
                        const balanceBtnRespInt = (Number(jwallBalanceResp.data.result.stack[0][1]) / 10 ** 6).toFixed(9);
                        console.log(balanceBtnRespInt);
                        balanceJetton = Number(balanceBtnRespInt);
                    }
                    else {
                        console.error('data not ok');
                    }
                }
                else {
                    console.error('address uninitialized');
                }
            }
            else {
                console.error('result error', addressWallet);
            }
        }
        else {
            console.error('null addressWallet', addressWallet);
        }
        return balanceJetton;
    }
    async function getBalanceTon(addressW = address) {
        const BalanceTon = await tonrpc.request('getAddressBalance', { address: addressW });
        // console.log(BalanceTon.data.result)
        const balTon = (BalanceTon.data.result / 10 ** 9).toFixed(9);
        return balTon;
    }
    function resetFull() {
    }
    useEffect(() => {
        enableBtn();
    }, [balanceTONFULL]);
    useEffect(() => {
        if (data) {
            console.log('balance222', Number(data.formatted));
        }
    }, [data]);
    // получение баланса ефира
    useEffect(() => {
        if (resGetBalance.isLoading === false) {
            // console.log('balance: ', resGetBalance.data?._hex)
            if (resGetBalance.data) {
                const bl = Number(resGetBalance.data._hex);
                if (bl) {
                    // console.log()
                    setBalanceETH(balanceString(bl / 10 ** 6));
                }
            }
        }
    }, [resGetBalance]);
    // подключение к тонхаб
    useEffect(() => {
        // console.log(connectTonHub)
        // if (connectTonHub.state.type === 'initing') {
        //     const getD = getTonHub()
        //     if (getD) {
        //         props.setConnectionState(getD)
        //     }
        // }
        console.log('connectTonHub', connectTonHub);
        if (connectTonHub.state.type === 'pending') {
            const getD = getTonHub();
            if (getD) {
                props.setConnectionState(getD);
            }
        }
        if (connectTonHub.state.type === 'online') {
            const connectTonHubFix = connectTonHub;
            saveTonHub({
                type: 'online',
                session: connectTonHubFix.state.session,
                seed: connectTonHubFix.state.seed,
                walletConfig: connectTonHubFix.state.walletConfig
            });
            // console.log('connectTonHub', connectTonHub)
            getJettonWalletAddress(addressUSDTTON, connectTonHubFix.state.walletConfig.address).then((addr) => {
                getJettonBalanceFromWalletAddress(addr).then((balance) => {
                    setBalanceTON(balanceString(balance));
                    getBalanceTon(connectTonHubFix.state.walletConfig.address).then((bal) => {
                        setBalanceTONFULL(bal);
                        console.log('balance=====', bal);
                        // setTimeout(() => {
                        //     enableBtn(bal)
                        //     inputNumberSet('10', false)
                        // }, 3000)
                        // enableBtn(bal)
                    });
                });
            });
        }
    }, [connectTonHub.state.type]);
    // подключение тонхаб и валетконет
    useEffect(() => {
        if (connectTonHub.state.type === 'online' && isConnected) {
            // enableBtn()
            setTextBtn(`${Ln(St.exchange)} ${inp1} USDT`);
            const addr = new Address(connectTonHub.state.walletConfig.address);
            console.log('Address', addr.hash);
            // enableBtn()
        }
    }, [connectTonHub.state.type, isConnected]);
    // подключение валетконект
    useEffect(() => {
        // console.log(isConnected)
        if (TgObj.initDataUnsafe.user) {
            if (TgObj.initDataUnsafe.user.language_code === 'ru') {
                lang = TgObj.initDataUnsafe.user.language_code;
            }
            TgObj.ready();
        }
        else { // browser
        }
        if (step === 0) {
            TgObj.MainButton.text = Ln(St.exchange);
            enableBtn();
            TgObj.enableClosingConfirmation();
            TgObj.expand();
        }
        // if (modal) {
        //     TgObj.MainButton.hide()
        // } else {
        //     TgObj.MainButton.show()
        // }
        if (isConnected) {
            TgObj.MainButton.hideProgress();
            enableBtn();
            if (step === 1) {
                enableBtn();
                setStep(2);
            }
            console.log('connector', connector);
            if (isInstalledBtn === false) {
                TgObj.MainButton.onClick(() => {
                    console.log('1');
                    approveS();
                    TgObj.MainButton.hide();
                    // approve.write()
                });
                isInstalledBtn = true;
            }
        }
        setStep(1);
        if (isConnected === false) {
            TgObj.MainButton.hideProgress();
            disableBtn();
        }
    }, [isConnected]);
    // общий
    useEffect(() => {
        const load = async () => {
            calculateAmountNew('10');
            // const getD = getTonHub()
            // if (getD) {
            //     props.setConnectionState(getD)
            // } else {
            //     props.setConnectionState({ type: 'initing' })
            // }
        };
        load();
    }, []);
    return (_jsx(AppRoot, { children: _jsx(SplitLayout, { style: { justifyContent: 'center' }, children: _jsx(SplitCol, { fixed: true, animate: !isDesktop, spaced: isDesktop, width: isDesktop ? '500px' : '100%', maxWidth: isDesktop ? '500px' : '100%', children: _jsxs(View, { activePanel: activePanel, children: [_jsx(Panel, { id: "panel1", children: _jsx(Group, { children: _jsxs("div", { children: [_jsxs("div", { className: '_blockswap', style: {
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                paddingBottom: 24,
                                                paddingTop: 8
                                            }, children: [_jsx(FormItem, { top: `${Ln(St.you_send)} ${coins[typeV].name}`, bottom: `≈ ${inp2 < 0 ? '0' : inp2} ${coins[Number(typeV) === 0 ? 1 : 0].name}`, children: _jsx(Input, { placeholder: '0', type: "number", min: "10", autoFocus: false, pattern: "\\d*", inputMode: "decimal", maxLength: 7, value: inp1, onChange: (e) => {
                                                            calculateAmountNew(inputNumberSet(e.target.value, 0));
                                                        } }) }), _jsx(FormItem, { children: _jsx(IconButton, { className: "_swap", onClick: () => setTypeV(Number(typeV) === 0 ? 1 : 0), children: _jsx(Icon28SortOutline, { fill: "var(--accent)" }) }) })] }), _jsx(Div, { children: _jsx(Headline, { level: "1", style: { marginTop: 12, fontSize: '17px', fontWeight: '500' }, children: Ln(St.wallets) }) }), _jsxs(CardGrid, { size: "l", children: [_jsx(Card, { children: isConnected
                                                        ? _jsx(SimpleCell, { before: _jsx(Avatar, { src: wc, size: 40 }), after: _jsx(IconButton, { onClick: () => disconnectBtn(), children: _jsx(Icon28DoorArrowRightOutline, {}) }), description: (isLoading ? '≈ 0 USDT • ERC20' : `≈ ${balanceETH} USDT | ERC20`), children: truncAddress(address) })
                                                        : _jsx(SimpleCell, { before: _jsx(Avatar, { src: wc, size: 40 }), after: _jsx(Icon28AddOutline, {}), onClick: connectLoad, children: Ln(St.connect_wallet) }) }), _jsx(Card, { children: connectTonHub.state.type === 'online'
                                                        ? _jsx(SimpleCell, { before: _jsx(Avatar, { src: 'https://tonhub.com/favicon-48x48.png', className: "pdng", size: 40 }), after: _jsx(IconButton, { onClick: () => disconectTon(), children: _jsx(Icon28DoorArrowRightOutline, {}) }), description: `≈ ${balanceTON} USDT | TON`, children: truncAddress(connectTonHub.state.walletConfig.address) })
                                                        : _jsx(SimpleCell, { before: _jsx(Avatar, { src: 'https://tonhub.com/favicon-48x48.png', className: "pdng", size: 40 }), after: _jsx(Icon28AddOutline, {}), onClick: () => LinkTG(connectTonHub.state.type === 'pending'
                                                                ? connectTonHub.state.link : ''), children: Ln(St.connect_wallet) }) })] }), (Number(data?.formatted) ?? 1) > 0.005 && balanceTONFULL > 1
                                            ? null
                                            : _jsx(Banner
                                            // before={
                                            //     <Avatar
                                            //         size={28}
                                            //         style={{ backgroundImage: warningGradient }}>
                                            //         <span style={{ color: '#fff' }}>!</span>
                                            //     </Avatar>
                                            // }
                                            , { 
                                                // before={
                                                //     <Avatar
                                                //         size={28}
                                                //         style={{ backgroundImage: warningGradient }}>
                                                //         <span style={{ color: '#fff' }}>!</span>
                                                //     </Avatar>
                                                // }
                                                header: Ln(St.warning), subheader: _jsx(React.Fragment, { children: Ln(St.warning_desc) }) }), !isTg
                                            ? _jsx(Button, { size: "l", stretched: true, onClick: () => approveS(), children: "\u041E\u0431\u043C\u0435\u043D\u044F\u0442\u044C" })
                                            : null] }) }) }), _jsx(Panel, { id: "panel2", children: _jsx(Group, { children: _jsx(RootSteps, { addressUSDT: addressUSDT, addressBridge: addressBridge, address: address, connectTonHub: connectTonHub, inp1: inp1, resetFull: resetFull, Ln: Ln, addressBridgeTon: addressBridgeTon, typeV: typeV, getJettonWalletAddress: getJettonWalletAddress, addressUSDTTON: addressUSDTTON }) }) })] }) }) }) }));
};
