import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AdaptivityProvider, ConfigProvider, WebviewType, usePlatform } from '@vkontakte/vkui';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { chains, providers } from '@web3modal/ethereum';
import { Web3Modal } from '@web3modal/react';
import { TonhubConnectProvider } from 'react-ton-x';
import './zoom.css';
import { App } from './App';
// const root = ReactDOM.createRoot(
//     document.getElementById('root') as HTMLElement
// )
// const configRpc = {
//     rpc: (chain: Chain) => {
//         http: string;
//         webSocket?: string;
//     } | null;
//     static?: boolean;
// }
const modalConfig = {
    projectId: '2e6208d8c73f2b1560e96b4e757bb4a1',
    theme: 'dark',
    accentColor: 'blue',
    ethereum: {
        appName: 'Bridge',
        autoConnect: true,
        chains: [
            chains.mainnet
        ],
        providers: [providers.walletConnectProvider({ projectId: '2e6208d8c73f2b1560e96b4e757bb4a1' })]
        // providers: [ providers.jsonRpcProvider({ rpc: () => ({ http: 'http://10.6.0.6:8545' }) }) ]
    }
};
const ConfigProviderFix = ConfigProvider;
const AdaptivityProviderFix = AdaptivityProvider;
const el = document.createElement('div');
document.body.appendChild(el);
// eruda.init({
//     container: el,
//     tool: [ 'console', 'elements' ]
// })
// Hook
function useLocalStorage(key, initialValue) {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState(() => {
        if (typeof window === 'undefined') {
            return initialValue;
        }
        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(key);
            // Parse stored json or if none return initialValue
            return item ? JSON.parse(item) : initialValue;
        }
        catch (error) {
            // If error also return initialValue
            console.log(error);
            return initialValue;
        }
    });
    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = (value) => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            // Save state
            setStoredValue(valueToStore);
            // Save to local storage
            if (typeof window !== 'undefined') {
                window.localStorage.setItem(key, JSON.stringify(valueToStore));
            }
        }
        catch (error) {
            // A more advanced implementation would handle the error case
            console.log(error);
        }
    };
    return [storedValue, setValue];
}
const Root = () => {
    const platform = usePlatform();
    const [connectionState, setConnectionState] = useLocalStorage('connection', { type: 'initing' });
    const [name, setName] = React.useState('');
    const [theme, setTheme] = React.useState('dark');
    useEffect(() => {
        const platform2 = window.navigator.platform;
        console.log(platform2);
        if (platform2 === 'Win32'
            || (platform2.indexOf('Mac')) === 0) {
            setName('test_zoom');
        }
        setTheme(window.Telegram.WebApp.colorScheme);
    }, []);
    return (_jsxs(React.StrictMode, { children: [_jsx(TonhubConnectProvider, { network: "mainnet", url: "https://t.me", name: "Bridge", debug: false, connectionState: connectionState, setConnectionState: setConnectionState, children: _jsx(ConfigProviderFix, { appearance: theme, webviewType: WebviewType.INTERNAL, platform: platform, children: _jsx(AdaptivityProviderFix, { children: _jsx(App, { setConnectionState: setConnectionState }) }) }) }), _jsx("div", { className: name, children: _jsx(Web3Modal, { config: modalConfig }) })] }));
};
// const container = document.querySelector('#root')
// const root = createRoot(container!)
// root.render(<Root/>)
ReactDOM.render(_jsx(Root, {}), document.querySelector('#root'));
