export const St = {
    you_send: {
        en: 'You send',
        ru: 'Отправляете'
    },
    wallets: {
        en: 'Wallets',
        ru: 'Кошельки'
    },
    connect_wallet: {
        en: 'Connect Wallet',
        ru: 'Connect Wallet'
    },
    exchange: {
        en: 'Exchange',
        ru: 'Обменять'
    },
    proccess: {
        en: 'Proccess',
        ru: 'Процесс'
    },
    approve_tokens: {
        en: 'Approve your tokens',
        ru: 'Approve your tokens'
    },
    approve_disc: {
        en: 'Go to your Ethereum wallet and allow the smart contract to spend your tokens',
        ru: 'Зайдите в ваш кошелек Ethereum и разрешите смарт-контракту потратить токены'
    },
    transfer_tokens: {
        en: 'Push the transaction',
        ru: 'Push the transaction'
    },
    transfer_disc: {
        en: 'Go to your Ethereum wallet and call the contract to send an cross-chain swap request',
        ru: 'Зайдите в свой кошелек Ethereum и вызовите контракт для отправки запроса на обмен'
    },
    release_tokens: {
        en: 'Release your funds',
        ru: 'Release your funds'
    },
    release_disc: {
        en: 'Go to your TON wallet and call the contract to receive your USDT tokens as instant',
        ru: 'Зайдите в свой кошелек TON и вызовите контракт, чтобы получить токены USDT'
    },
    success: {
        en: 'Successful! USDT tokens will appear on your TON wallet balance in a few seconds',
        ru: 'Успешно! Токены USDT появятся на балансе вашего кошелька TON через несколько секунд'
    },
    success_ton: {
        en: 'Successful! USDT tokens will appear on your Ethereum wallet balance in a few seconds',
        ru: 'Успешно! Токены USDT появятся на балансе вашего кошелька Ethereum через несколько секунд'
    },
    transfer_tokens_ton: {
        en: 'Push the transaction',
        ru: 'Push the transaction'
    },
    release_tokens_ton: {
        en: 'Release your funds',
        ru: 'Release your funds'
    },
    release_disc_ton: {
        en: 'Go to your Ethereum wallet and call the contract to receive your USDT tokens as instant',
        ru: 'Зайдите в свой кошелек Ethereum и вызовите контракт, чтобы получить токены USDT'
    },
    warning: {
        en: 'Warning',
        ru: 'Предупреждение'
    },
    warning_desc: {
        en: 'You must have a balance from 0.01 ETH and 1 TON to pay for bridge smart contracts gas',
        ru: 'Для оплаты газа за использование смарт-контрактов моста вам необходимо иметь баланс от 0.01 ETH и 1 TON',
    },
    transfer_disc_ton: {
        en: 'Go to your TON wallet and call the contract to transfer your USDT tokens as instant',
        ru: 'Зайдите в свой кошелек TON и вызовите контракт, чтобы отправить токены USDT'
    },
    cancel: {
        en: 'Do you really want to cancel the operation?',
        ru: 'Вы хотите отменить операцию?'
    }
};
