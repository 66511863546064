import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useContractRead, useContractWrite } from '@web3modal/react';
import { BigNumber, ethers } from 'ethers';
import { chains } from '@web3modal/ethereum';
import { Div, IconButton, SimpleCell } from '@vkontakte/vkui';
import { Icon28CancelCircleOutline, Icon28CheckCircleOff, Icon28CheckCircleOn, Icon28Clock, Icon28ReplayOutline } from '@vkontakte/icons';
import Lottie from 'react-lottie';
import { Utils, Address, Builder, Coins, BOC } from 'ton3';
import { randomBytes } from 'crypto';
import { St } from '../lang';
import '@vkontakte/vkui/dist/vkui.css';
import '../style.css';
import wagmigotchiABI from '../abi.json';
import wagmigotchiABI2 from '../abi2.json';
import * as approveJSON from '../static/approve_animation.json';
import * as pushJSON from '../static/push_tx_animation.json';
import * as releaseJSON from '../static/release_animation.json';
import * as successfulJSON from '../static/successful_animation.json';
const approveOptions = {
    loop: true,
    autoplay: true,
    animationData: approveJSON,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
};
const pushOptions = {
    loop: true,
    autoplay: true,
    animationData: pushJSON,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
};
const releaseOptions = {
    loop: true,
    autoplay: true,
    animationData: releaseJSON,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
};
const successfulOptions = {
    loop: true,
    autoplay: true,
    animationData: successfulJSON,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
};
const axios = require('axios').default;
const ch = chains.mainnet.id;
const server1 = 'https://toton.bridgebot.app';
const server2 = 'https://toeth.bridgebot.app';
async function gteDataApi(url) {
    const data2 = await axios.get(url);
    return data2;
}
async function statusServer() {
    const status2 = await gteDataApi(`${server1}/status`);
    // console.log(status2.data)
    if (status2.data) {
        if (status2.data.remains < 100
            && status2.data.updated_at > Math.floor(Date.now() / 1000) - (60 * 5)) {
            return true;
        }
    }
    return false;
}
async function statusServer2() {
    const status2 = await gteDataApi(`${server2}/status`);
    // console.log(status2.data)
    if (status2.data) {
        if (status2.data.remains < 100
            && status2.data.updated_at > Math.floor(Date.now() / 1000) - (60 * 5)) {
            return true;
        }
    }
    return false;
}
async function getHashServer(hash) {
    const hashData = await gteDataApi(`${server1}/data/${hash}`);
    if (hashData.data !== '') {
        console.log('hash!!!', hashData.data);
        return hashData.data;
    }
    return null;
}
async function getHashServer2(hash) {
    const hashData = await gteDataApi(`${server2}/data/0x${hash}`);
    if (hashData.data !== '') {
        if (hashData.data.txHash !== '') {
            console.log('hash!!!', hashData.data);
            return hashData.data;
        }
    }
    return null;
}
async function interval2(hash, cb) {
    let returnData = null;
    let count = 0;
    const inn = setInterval(async () => {
        count += 1;
        if (count === 100) {
            clearInterval(inn);
        }
        const obDat = await getHashServer(hash);
        if (obDat) {
            returnData = obDat;
            clearInterval(inn);
            cb(returnData);
        }
    }, 5000);
}
async function interval22(hash, cb) {
    let returnData = null;
    let count = 0;
    const inn = setInterval(async () => {
        count += 1;
        if (count === 100) {
            clearInterval(inn);
        }
        const obDat = await getHashServer2(hash);
        if (obDat) {
            returnData = obDat;
            clearInterval(inn);
            cb(returnData);
        }
    }, 2000);
}
const FirstStep = (props) => {
    const approve = useContractWrite({
        addressOrName: props.props.addressUSDT,
        contractInterface: wagmigotchiABI,
        functionName: 'approve',
        args: [props.props.addressBridge, BigNumber.from('2').pow(64).add(-1)],
        chainId: ch
    });
    const isApprove = useContractRead({
        addressOrName: props.props.addressUSDT,
        contractInterface: wagmigotchiABI,
        functionName: 'allowance',
        args: [props.props.address ?? '', props.props.addressBridge],
        chainId: ch
    });
    const [position, setPosition] = React.useState(0);
    // событие проверки апрува
    useEffect(() => {
        if (position === 0 && props.cursorStep === 0) {
            if (isApprove.isLoading === false) {
                if (isApprove.data) {
                    const bl = BigInt(isApprove.data?._hex);
                    setPosition(1);
                    statusServer().then((isStatus) => {
                        console.log('SERVER==', position);
                        if (isStatus) {
                            if (bl > BigInt(Number(props.props.inp1) * (10 ** 6))) {
                                console.log('isApprove', isApprove.data);
                                props.setCursorStep(1);
                            }
                            else if (!props.isWait) {
                                props.setIsWait(true);
                                approve.write();
                                console.log('server ok', isApprove.data);
                            }
                            else {
                                console.log('wait', props.isWait);
                            }
                        }
                        else {
                            console.error('server not active status');
                            props.props.resetFull();
                        }
                    }).catch((error2) => {
                        console.error('server not active status 2', error2);
                        // props.setReloadStep(!props.reloadStep)
                        props.setError(true);
                    });
                }
                else {
                    console.log('now error!!!');
                }
            }
            if (isApprove.error) {
                console.error('error isApprove', isApprove.error);
                props.setIsWait(false);
                props.setError(true);
                // props.setReloadStep(!props.reloadStep)
            }
        }
    }, [isApprove]);
    // событие апрува
    useEffect(() => {
        if (position < 2 && props.cursorStep === 0) { // 0, 1
            if (approve.data) {
                if (approve.data.hash) {
                    setPosition(1);
                    if (position === 1) {
                        approve.data.wait(1).then((waitData) => {
                            console.log(waitData);
                            setPosition(2);
                            props.setIsWait(false);
                            props.setCursorStep(1);
                        });
                    }
                }
            }
            else {
                // console.error('error approve 222')
                // props.setError(true)
            }
            if (approve.error) {
                console.error('error approve', approve.error);
                props.setIsWait(false);
                // props.setReloadStep(!props.reloadStep)
                props.setError(true);
            }
        }
    }, [approve.data, approve.error]);
    return (_jsx(SimpleCell, { before: _jsxs("span", { style: { display: 'flex', alignItems: 'center', height: '100%', marginRight: 8 }, children: [props.error && props.cursorStep === 0 ? _jsx(Icon28CancelCircleOutline, { fill: 'var(--field_error_border)' }) : null, props.cursorStep === 0 && !props.error ? _jsx(Icon28Clock, { fill: 'var(--accent)' }) : null, props.cursorStep !== 0 ? _jsx(Icon28CheckCircleOn, { fill: '#31b545' }) : null] }), after: props.error && props.cursorStep === 0
            ? _jsx(IconButton, { onClick: () => props.setReloadStep(!props.reloadStep), children: _jsx(Icon28ReplayOutline, {}) })
            : null, 
        // caption={props.props.Ln(St.approve_disc)}
        disabled: true, children: props.props.Ln(St.approve_tokens) }));
};
const SeondStep = (props) => {
    const resSendETH = useContractWrite({
        addressOrName: props.props.addressBridge,
        contractInterface: wagmigotchiABI2,
        functionName: 'swapToTON',
        args: [props.props.connectTonHub.state.type === 'online'
                ? new Address(props.props.connectTonHub.state.walletConfig.address).hash : '',
            Number(props.props.inp1) * (10 ** 6)],
        chainId: ch
    });
    const [position, setPosition] = React.useState(0);
    const [startWrite, setStartWrite] = React.useState(false);
    useEffect(() => {
        if (position < 2 && props.cursorStep === 1 && props.error === false) {
            if (resSendETH.data) {
                if (resSendETH.data.hash) {
                    // setPosition(1)
                    if (position === 0) {
                        setPosition(1);
                        resSendETH.data.wait(6).then((waitData) => {
                            console.log('waitData', waitData);
                            setPosition(2);
                            // transferS()
                            props.setTransHash(waitData.transactionHash);
                            interval2(waitData.transactionHash, (nowHash) => {
                                props.setCursorStep(2);
                                console.log('nowHash', nowHash);
                                props.setHashData(nowHash);
                                props.setIsWait(false);
                            });
                        });
                    }
                }
            }
            if (resSendETH.error) {
                console.error('error resSendETH', resSendETH.error);
                props.setIsWait(false);
                props.setError(true);
                // props.setReloadStep(!props.reloadStep)
            }
        }
    }, [resSendETH]);
    useEffect(() => {
        // console.log('false', startWrite)
        // console.log('false', props.isWait)
        // console.log('1', props.cursorStep)
        if (!startWrite && !props.isWait && props.cursorStep === 1) {
            setStartWrite(true);
            resSendETH.write();
            props.setIsWait(true);
            console.log('props.isWait-------', props.isWait);
            // console.log('resSendETH.write()', resSendETH.write())
        }
    }, []);
    return (_jsx(SimpleCell, { style: props.cursorStep < 1 ? { opacity: '0.5' } : {}, before: _jsxs("span", { style: { display: 'flex', alignItems: 'center', height: '100%', marginRight: 8 }, children: [props.error && props.cursorStep === 1 ? _jsx(Icon28CancelCircleOutline, { fill: 'var(--field_error_border)' }) : null, props.cursorStep < 1 ? _jsx(Icon28CheckCircleOff, { style: { opacity: '0.5' } }) : null, props.cursorStep === 1 && !props.error ? _jsx(Icon28Clock, { fill: 'var(--accent)' }) : null, props.cursorStep > 1 ? _jsx(Icon28CheckCircleOn, { fill: '#31b545' }) : null] }), after: props.error && props.cursorStep === 1
            ? _jsx(IconButton, { onClick: () => props.setReloadStep(!props.reloadStep), children: _jsx(Icon28ReplayOutline, {}) })
            : null, 
        // caption={props.props.Ln(St.transfer_disc)}
        disabled: true, children: props.props.Ln(St.transfer_tokens) }));
};
const ThirdStep = (props) => {
    // const [ position, setPosition ] = React.useState<any>(0)
    const [firstRender, setFirstRender] = React.useState(false);
    async function sendTon(payload2) {
        const request = {
            to: props.props.addressBridgeTon,
            value: '1000000000',
            payload: payload2
        };
        // console.log(props.props.connectTonHub.api.requestTransaction)
        const data2 = await props.props.connectTonHub.api.requestTransaction(request);
        console.log('data2', data2);
        if (data2.type === 'success') {
            props.setCursorStep(3);
        }
    }
    useEffect(() => {
        if (firstRender === false && props.hashData !== '' && props.props.connectTonHub.state.type === 'online') {
            setFirstRender(true);
            const arrayBytes = Utils.Helpers.hexToBytes(props.hashData);
            console.log('arrayBytes', arrayBytes);
            const arrayBytes8 = arrayBytes.slice(0, 8);
            const arrayBytes82 = arrayBytes.slice(8, 16);
            const arrayBytes32 = arrayBytes.slice(16, 32 + 16);
            const arrayBytes64 = arrayBytes.slice(32 + 16, arrayBytes.length);
            console.log('arrayBytes8', arrayBytes8);
            console.log('arrayBytes8_2', arrayBytes82);
            console.log('arrayBytes32', arrayBytes32);
            console.log('arrayBytes64', arrayBytes64);
            console.log('arrayBytes32_hex', Utils.Helpers.bytesToHex(arrayBytes32));
            const bytesAddressUser = Utils.Helpers.hexToBytes(props.props.address?.replace('0x', '') ?? '');
            // console.log('bytesAddressUser', bytesAddressUser)
            const bytesAddressUserTon = new Address(props.props.connectTonHub.state.walletConfig.address).hash;
            // console.log(bytesAddressUserTon)
            const transHashArray = Utils.Helpers.hexToBytes(props.transHash.replace('0x', '') ?? '');
            const fee = ((Number(props.props.inp1) * (10 ** 6)) / 100) + (5 * (10 ** 5));
            console.log('fee', fee);
            const amountUSDT = Coins.fromNano(Number(props.props.inp1) * (10 ** 6), 6);
            const amountFee = Coins.fromNano(fee, 6);
            const dataCell = new Builder()
                .storeBytes(bytesAddressUser)
                .storeBytes(bytesAddressUserTon)
                .storeBytes(transHashArray)
                .storeCoins(amountUSDT)
                .storeCoins(amountFee)
                .storeBytes(arrayBytes8)
                .storeBytes(arrayBytes82)
                .cell();
            // console.log('dataCell', dataCell.hash())
            // console.log('textCell', Utils.Helpers.bitsToHex(textCell.bits))
            // console.log('textCell', Utils.Helpers.bytesToHex(arrayBytes8))
            // console.log('textCell2', Utils.Helpers.bitsToHex(textCell.bits))
            // console.log('textCell2', Utils.Helpers.bytesToHex(arrayBytes8_2))
            // console.log('transHashArray', Utils.Helpers.bytesToHex(transHashArray))
            const signDataCell = new Builder()
                .storeBytes(arrayBytes64)
                .storeBytes(arrayBytes32)
                .cell();
            const payloadCell = new Builder()
                .storeUint(1003, 32)
                .storeRef(signDataCell)
                .storeRef(dataCell)
                .cell();
            const fullPayload = BOC.toBase64Standard(payloadCell);
            console.log('connectTonHub', props.props.connectTonHub);
            sendTon(fullPayload);
            // props.props.addressBridgeTon
        }
    }, []);
    return (_jsx(SimpleCell, { style: props.cursorStep < 1 ? { opacity: '0.5' } : {}, before: _jsxs("span", { style: { display: 'flex', alignItems: 'center', height: '100%', marginRight: 8 }, children: [props.error && props.cursorStep === 2 ? _jsx(Icon28CancelCircleOutline, { fill: 'var(--field_error_border)' }) : null, props.cursorStep < 2 ? _jsx(Icon28CheckCircleOff, { style: { opacity: '0.5' } }) : null, props.cursorStep === 2 && !props.error ? _jsx(Icon28Clock, { fill: 'var(--accent)' }) : null, props.cursorStep > 2 ? _jsx(Icon28CheckCircleOn, { fill: '#31b545' }) : null] }), 
        // caption={props.props.Ln(St.release_disc)}
        after: props.error && props.cursorStep === 2
            ? _jsx(IconButton, { onClick: () => props.setReloadStep(!props.reloadStep), children: _jsx(Icon28ReplayOutline, {}) })
            : null, disabled: true, children: props.props.Ln(St.release_tokens) }));
};
const FirstStepTon = (props) => {
    // const [ position, setPosition ] = React.useState<any>(0)
    const [firstRender, setFirstRender] = React.useState(false);
    const [randomKey] = React.useState(randomBytes(32));
    async function sendTon(address2, payload2, hash2) {
        const request = {
            to: address2,
            value: '500000000',
            payload: payload2
        };
        // console.log(props.props.connectTonHub.api.requestTransaction)
        const data2 = await props.props.connectTonHub.api.requestTransaction(request);
        console.log('data2', data2);
        if (data2.type === 'success') {
            // props.setCursorStep(2)
            interval22(hash2, (nowHash) => {
                console.log(nowHash);
                if (props.setHashData2 && props.setRandomKey) {
                    props.setRandomKey(randomKey);
                    props.setHashData2(nowHash);
                }
                props.setCursorStep(1);
            });
        }
        else {
            props.setError(true);
            console.error('tonhub', data2);
        }
    }
    useEffect(() => {
        if (firstRender === false && props.props.connectTonHub.state.type === 'online') {
            setFirstRender(true);
            statusServer2().then((isStatus) => {
                if (isStatus) {
                    const connectTonHubFixed = props.props.connectTonHub;
                    const addressTon = connectTonHubFixed.state.walletConfig.address ?? '';
                    const bytesAddressUserTon = new Address(addressTon);
                    const amountUSDT = Coins.fromNano(Number(props.props.inp1) * (10 ** 6), 6);
                    const bytesAddressUser = Utils.Helpers.hexToBytes(props.props.address?.replace('0x', '') ?? '');
                    props.props.getJettonWalletAddress(props.props.addressUSDTTON, addressTon).then((returnData) => {
                        if (returnData) {
                            console.log('returnData', returnData);
                            const body = new Builder()
                                .storeUint(0x0f8a7ea5, 32)
                                .storeUint(0, 64)
                                .storeCoins(amountUSDT)
                                .storeAddress(new Address(props.props.addressBridgeTon))
                                .storeAddress(bytesAddressUserTon)
                                .storeUint(0, 1)
                                .storeCoins(new Coins('0.05'))
                                .storeUint(1, 1)
                                .storeRef(new Builder()
                                .storeBytes(bytesAddressUser)
                                .storeBytes(randomKey)
                                .cell())
                                .cell();
                            const fullPayload = BOC.toBase64Standard(body);
                            const sendHash = new Builder()
                                .storeBytes(randomKey)
                                .storeBytes(bytesAddressUser)
                                .storeCoins(amountUSDT)
                                .cell()
                                .hash();
                            sendTon(returnData, fullPayload, sendHash);
                        }
                    });
                }
                else {
                    props.setError(true);
                    console.error('server 2 error');
                }
            }).catch((error2) => {
                console.error('server 2 not active status 2', error2);
                // props.setReloadStep(!props.reloadStep)
                props.setError(true);
            });
        }
    }, []);
    return (_jsx(SimpleCell, { before: _jsxs("span", { style: { display: 'flex', alignItems: 'center', height: '100%', marginRight: 8 }, children: [props.error && props.cursorStep === 0 ? _jsx(Icon28CancelCircleOutline, { fill: 'var(--field_error_border)' }) : null, props.cursorStep === 0 && !props.error ? _jsx(Icon28Clock, { fill: 'var(--accent)' }) : null, props.cursorStep > 0 ? _jsx(Icon28CheckCircleOn, { fill: '#31b545' }) : null] }), 
        // caption={props.props.Ln(St.release_disc)}
        after: props.error && props.cursorStep === 0
            ? _jsx(IconButton, { onClick: () => props.setReloadStep(!props.reloadStep), children: _jsx(Icon28ReplayOutline, {}) })
            : null, disabled: true, children: props.props.Ln(St.transfer_tokens_ton) }));
};
const SeondStepTon = (props) => {
    const [position, setPosition] = React.useState(0);
    const [firstRender, setFirstRender] = React.useState(false);
    const connectTonHubFixed = props.props.connectTonHub;
    const addressTon = connectTonHubFixed.state.walletConfig.address ?? '';
    const hexAddressUserTon = Utils.Helpers.bytesToHex(new Address(addressTon).hash);
    // console.log(hexAddressUserTon)
    const argsSend = props.hashData2 && props.randomKey ? {
        destination: props.props.address,
        accountTON: `0x${hexAddressUserTon}`,
        salt: `0x${Utils.Helpers.bytesToHex(props.randomKey)}`,
        txhashTON: `0x${props.hashData2.txHash}`,
        ltimeTON: Number(props.hashData2.lt),
        amount: Number(props.props.inp1) * (10 ** 6),
        signts: Number(props.hashData2.signTS)
    } : {};
    const argsSend2 = props.hashData2 ? ethers.utils.splitSignature(`0x${props.hashData2.signature}`) : [];
    const resGiveETH = useContractWrite({
        addressOrName: props.props.addressBridge,
        contractInterface: wagmigotchiABI2,
        functionName: 'swapToERC',
        args: [argsSend, argsSend2],
        chainId: ch
    });
    useEffect(() => {
        if (position === 0 && props.cursorStep === 1 && props.error === false && props.hashData2) {
            if (resGiveETH.data) {
                if (resGiveETH.data.hash) {
                    if (position === 0) {
                        setPosition(1);
                        resGiveETH.data.wait(1).then((waitData) => {
                            console.log('waitData2', waitData);
                            props.setCursorStep(3);
                        });
                    }
                }
            }
            if (resGiveETH.error) {
                console.log('DATA', JSON.stringify(Object.values(argsSend)));
                let ttt = Object.values(argsSend2);
                ttt = [ttt[4], ttt[0], ttt[1]];
                console.log('SIGN', JSON.stringify(ttt));
                console.error('error resGiveETH', resGiveETH.error);
                props.setIsWait(false);
                props.setError(true);
                // props.setReloadStep(!props.reloadStep)
            }
        }
    }, [resGiveETH]);
    useEffect(() => {
        if (firstRender === false && props.hashData2 && props.isWait === false) {
            setFirstRender(true);
            props.setIsWait(true);
            resGiveETH.write();
        }
    }, []);
    return (_jsx(SimpleCell, { style: props.cursorStep < 1 ? { opacity: '0.5' } : {}, before: _jsxs("span", { style: { display: 'flex', alignItems: 'center', height: '100%', marginRight: 8 }, children: [props.error && props.cursorStep === 1 ? _jsx(Icon28CancelCircleOutline, { fill: 'var(--field_error_border)' }) : null, props.cursorStep < 1 ? _jsx(Icon28CheckCircleOff, { style: { opacity: '0.5' } }) : null, props.cursorStep === 1 && !props.error ? _jsx(Icon28Clock, { fill: 'var(--accent)' }) : null, props.cursorStep > 1 ? _jsx(Icon28CheckCircleOn, { fill: '#31b545' }) : null] }), after: props.error && props.cursorStep === 1
            ? _jsx(IconButton, { onClick: () => props.setReloadStep(!props.reloadStep), children: _jsx(Icon28ReplayOutline, {}) })
            : null, 
        // caption={props.props.Ln(St.transfer_disc)}
        disabled: true, children: props.props.Ln(St.release_tokens_ton) }));
};
export const RootSteps = (props) => {
    const [cursorStep, setCursorStep] = React.useState(0);
    const [reloadStep, setReloadStep] = React.useState(false);
    const [keyFirstStep, setKeyFirstStep] = React.useState('100');
    const [keySecondStep, setKeySecondStep] = React.useState('200');
    const [keyThirdStep, setKeyThirdStep] = React.useState('300');
    const [isWait, setIsWait] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [firstRender, setFirstRender] = React.useState(false);
    const [transHash, setTransHash] = React.useState('');
    const [hashData, setHashData] = React.useState('');
    const [hashData2, setHashData2] = React.useState(null);
    const [randomKey, setRandomKey] = React.useState(null);
    useEffect(() => {
        if (firstRender) {
            setTimeout(() => {
                if (cursorStep === 0) {
                    setKeyFirstStep(`${Number(keyFirstStep) + 1}`);
                }
                if (cursorStep === 1) {
                    // setIsWait(false)
                    setKeySecondStep(`${Number(keySecondStep) + 1}`);
                }
                if (cursorStep === 2) {
                    setKeyThirdStep(`${Number(keyThirdStep) + 1}`);
                }
                setError(false);
            }, 500);
            console.log('reloadStep', cursorStep);
        }
        setFirstRender(true);
    }, [reloadStep, cursorStep]);
    function fe() {
        if (cursorStep === 0) {
            return approveOptions;
        }
        if (cursorStep === 1) {
            return pushOptions;
        }
        if (cursorStep === 2) {
            return releaseOptions;
        }
        if (cursorStep === 3) {
            return successfulOptions;
        }
        return approveOptions;
    }
    return (_jsxs("div", { children: [props.typeV === 0
                ? _jsxs("div", { style: { paddingBottom: 16, paddingTop: 2, display: 'block' }, className: '_blockswap', children: [_jsx(FirstStep, { props: props, cursorStep: cursorStep, setCursorStep: setCursorStep, setReloadStep: setReloadStep, reloadStep: reloadStep, isWait: isWait, setIsWait: setIsWait, error: error, setError: setError, setHashData: setHashData, hashData: hashData, setTransHash: setTransHash, transHash: transHash }, keyFirstStep), _jsx(SeondStep, { props: props, cursorStep: cursorStep, setCursorStep: setCursorStep, setReloadStep: setReloadStep, reloadStep: reloadStep, isWait: isWait, setIsWait: setIsWait, error: error, setError: setError, setHashData: setHashData, hashData: hashData, setTransHash: setTransHash, transHash: transHash }, keySecondStep), _jsx(ThirdStep, { props: props, cursorStep: cursorStep, setCursorStep: setCursorStep, setReloadStep: setReloadStep, reloadStep: reloadStep, isWait: isWait, setIsWait: setIsWait, error: error, setError: setError, setHashData: setHashData, hashData: hashData, setTransHash: setTransHash, transHash: transHash }, keyThirdStep)] })
                : null, props.typeV === 1
                ? _jsxs("div", { style: { paddingBottom: 16, paddingTop: 2, display: 'block' }, className: '_blockswap', children: [_jsx(FirstStepTon, { props: props, cursorStep: cursorStep, setCursorStep: setCursorStep, setReloadStep: setReloadStep, reloadStep: reloadStep, isWait: isWait, setIsWait: setIsWait, error: error, setError: setError, setHashData: setHashData, hashData: hashData, setTransHash: setTransHash, transHash: transHash, setHashData2: setHashData2, setRandomKey: setRandomKey }, keyFirstStep), _jsx(SeondStepTon, { props: props, cursorStep: cursorStep, setCursorStep: setCursorStep, setReloadStep: setReloadStep, reloadStep: reloadStep, isWait: isWait, setIsWait: setIsWait, error: error, setError: setError, setHashData: setHashData, hashData: hashData, setTransHash: setTransHash, transHash: transHash, hashData2: hashData2, randomKey: randomKey }, keySecondStep)] })
                : null, _jsx("br", {}), props.typeV === 0
                ? _jsxs("div", { className: "utka2", children: [_jsx(Lottie, { options: fe(), height: 200, isClickToPauseDisabled: true, width: 200 }), _jsxs(Div, { style: { fontSize: '15px', fontWeight: '400', opacity: '0.6', textAlign: 'center' }, children: [cursorStep === 0 ? props.Ln(St.approve_disc) : null, cursorStep === 1 ? props.Ln(St.transfer_disc) : null, cursorStep === 2 ? props.Ln(St.release_disc) : null, cursorStep === 3 ? props.Ln(St.success) : null] })] })
                : null, props.typeV === 1
                ? _jsxs("div", { className: "utka2", children: [_jsx(Lottie, { options: fe(), height: 200, isClickToPauseDisabled: true, width: 200 }), _jsxs(Div, { style: { fontSize: '15px', fontWeight: '400', opacity: '0.6', textAlign: 'center' }, children: [cursorStep === 0 ? props.Ln(St.transfer_disc_ton) : null, cursorStep === 1 ? props.Ln(St.release_disc_ton) : null, cursorStep === 3 ? props.Ln(St.success_ton) : null] })] })
                : null] }));
};
export default RootSteps;
